<template>
  <div>
    <b-row>
      <b-col>
        Transaction History
      </b-col>
      <b-col>
        <b-row>
          <b-col>
            <b-form-group>
              <b-form-datepicker
                type="text"
                required
              >
              </b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-form-input
                placeholder="Search Name..."
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-table id="HistoryTable" :items="items" :fields="fields" show-empty empty-text="No transactions yet" :busy="loading">
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import moment from 'moment';
import * as api from "../../api";
export default {
  data(){
    return {
      items:[],
      buyer_id:null,
      loading:true,
      fields:[
        {key:"category",label:"Tag"},
        "amount",
        {key:"created_at", label:"Date",
        formatter:(item)=>{
          return moment(item).format("DD/MM/YYYY");
        }}
      ]
    }
  },
  mounted() {
    this.buyer_id = this.$route.params["buyer_id"];
    this.getTranx()
  },
  methods:{
    getTranx(){
      this.loading = true;
      api.transactions(this.buyer_id).then(data=>{
        this.loading = false;
        this.items = data;
      })
    }
  }
};
</script>

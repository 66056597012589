import { axiosApiInst } from "../../../../../../plugins/axios";
const BASE_URL = process.env.VUE_APP_BACKEND+"/buyers";
/**
 * 
 * @param {string} id
 * @returns {Promise<import("../../api").Buyer>} 
 */
async function find_one(id){
    const res = await axiosApiInst({
        url:BASE_URL+"/"+id
    });
    return res.data;
}
/**
 * 
 * @param {string} id 
 * @param {number} amount 
 * @returns {Promise<import("../../api").Wallet>}
 */
async function credit(id,amount,kind="cash"){
    const res = await axiosApiInst({
        url:BASE_URL+"/"+id+"/:credit",
        method:"post",
        data:{amount,kind}
    });
    return res.data;
}
async function debit(id,amount,kind="cash"){
    const res = await axiosApiInst({
        url:BASE_URL+"/"+id+"/:debit",
        method:"post",
        data:{amount,kind}
    });
    return res.data;
}
/**
 * 
 * @param {number} id 
 * @returns {Promise<import("../../api").Transaction>}
 */
async function transactions(id){
    const res = await axiosApiInst({
        url:BASE_URL+"/"+id+"/transactions"
    });
    return res.data
}
async function add_agent(id,data){
    const res = await axiosApiInst({
        url:BASE_URL+"/"+id+"/agents",
        method:"post",
        data:data
    });
    return res.data
}
/**
 * @param {number} id
 * @returns {Promise<import("../../api").Produce>}
 */
async function produce(id){
    const res = await axiosApiInst({
        url:BASE_URL+"/"+id+"/produce"
    })
    return res.data
}
export {find_one,credit,debit,transactions,produce,add_agent}